<template>
    <div role="status" v-if="isLoading">
        <loader-mini-component></loader-mini-component>
    </div>
    <form id="frmAlerts" name="frm-alerts" ref="frmAlerts">
        <div class="px-5 py-3 flex-auto text-left">
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 lg:w-fit mx-auto my-2 w-full">
                <table class="table-widget dark:text-surface-200 dark:bg-surface-800 w-full">
                    <tbody>
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Ingestion name:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">
                                    <div v-if="v$.entity.name.$error" class="text-red-600 text-sm">Please enter a Name for the alert.</div>
                                    <InputText type="text" v-model="entity.name" class="w-full"
                                               :invalid="v$.entity.name.$error" />
                                    <div :class="{ 'hidden': v$.entity.name.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.name.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <!-- status input -->
                            <td class="p-2"><span class="inline-block lg:w-32 w-full font-bold">Status:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">
                                    <div v-if="v$.entity.status.$error" class="text-red-600 text-sm">Please enter a status.</div>
                                    <Dropdown v-model="entity.status" :options="status" optionLabel="name" optionValue="id" placeholder="Please Select one"
                                              class="w-full" :invalid="v$.entity.status.$error" />                                    
                                </div>
                            </td>                    
                        </tr>
                        <tr class="flex flex-col lg:flex-row">
                            <!-- time input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Start Date:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">
                                    <div v-if="v$.entity.startDate.$error" class="text-red-600 text-sm">Please enter a start date.</div>                                    
                                    <div  class="">
                                        <Calendar                                            
                                        v-model="calendarDate"                                                                                
                                        :inline="false"
                                        :showIcon="false"
                                        dateFormat="yy-mm-dd"
                                        class=" rounded-lg p-2 w-full"                                                                              
                                        />                                        
                                    </div>
                                    <div :class="{ 'hidden': v$.entity.startDate.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.startDate.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>                                                         
                        </tr>
                    </tbody>
                </table>
            </div>  
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 lg:w-[55.5rem] mx-auto my-2 w-full">
                <table class="table-widget dark:text-surface-200 dark:bg-surface-800 w-full">
                    <tbody>                       
                        <tr class="flex flex-col">
                            <!-- Actions input -->
                            <td class="p-2"><span class="inline-block  w-full font-bold">Description:</span></td>
                            <td class="p-2">
                                <div class="inline-block  w-full">
                                    <div v-if="v$.entity.description.$error" class="text-red-600 text-sm">Please enter the actions to be executed.</div>
                                    <!-- Actions input -->
                                    <Textarea v-model="entity.description" class="w-full h-64"
                                              :invalid="v$.entity.description.$error" />
                                    <!--class="form-control text-sm block w-full p-1 font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />-->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 lg:w-fit mx-auto my-2 w-full">  
                <h1 class="p-2 dark:text-surface-200 font-bold text-xl" >Data Source</h1>              
                <table class="table-widget dark:text-surface-200 dark:bg-surface-800 w-full">                    
                    <tbody>                        
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Name:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataSource.name.$error" class="text-red-600 text-sm">Please enter a Name for data source.</div>                                  
                                    <InputText type="text" v-model="entity.dataSource.name" class="w-full"
                                    :invalid="v$.entity.dataSource.name.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataSource.name.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataSource.name.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>
                            <!-- status input -->
                            <td class="p-2"><span class="inline-block lg:w-32 w-full font-bold">Type:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataSource.type.$error" class="text-red-600 text-sm">Please enter a type for data source.</div>                                  
                                    <InputText type="text" v-model="entity.dataSource.type" class="w-full"
                                    :invalid="v$.entity.dataSource.type.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataSource.type.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataSource.type.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>                                                                                                                  
                        </tr> 
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Port:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataSource.port.$error" class="text-red-600 text-sm">Please enter a port for data source.</div>                                  
                                    <InputText type="text" v-model="entity.dataSource.port" class="w-full"
                                    :invalid="v$.entity.dataSource.port.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataSource.port.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataSource.port.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>
                            <!-- status input -->
                            <td class="p-2"><span class="inline-block lg:w-32 w-full font-bold">Host:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataSource.host.$error" class="text-red-600 text-sm">Please enter a host for data source.</div>                                  
                                    <InputText type="text" v-model="entity.dataSource.host" class="w-full"
                                    :invalid="v$.entity.dataSource.host.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataSource.host.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataSource.host.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>                                                                                                                  
                        </tr> 
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">User:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">                                    
                                    <InputText type="text" v-model="entity.dataSource.user" class="w-full"/>                                    
                                </div>
                            </td>
                            <!-- status input -->
                            <td class="p-2"><span class="inline-block lg:w-32 w-full font-bold">Password:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">                                    
                                    <InputText type="text" v-model="entity.dataSource.password" class="w-full"/>                                   
                                </div>
                            </td>                                                                                                                  
                        </tr> 
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Database:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataSource.database.$error" class="text-red-600 text-sm">Please enter database for data source.</div>                                  
                                    <InputText type="text" v-model="entity.dataSource.database" class="w-full"
                                    :invalid="v$.entity.dataSource.database.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataSource.database.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataSource.database.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>
                            <!-- status input -->
                            <td class="p-2"><span class="inline-block lg:w-32 w-full font-bold">Table:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataSource.table.$error" class="text-red-600 text-sm">Please enter a table for data source.</div>                                  
                                    <InputText type="text" v-model="entity.dataSource.table" class="w-full"
                                    :invalid="v$.entity.dataSource.table.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataSource.table.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataSource.table.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>                                                                                                                  
                        </tr>  
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Time Column:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">                                    
                                    <InputText type="text" v-model="entity.dataSource.timeColumn" class="w-full"/>                                    
                                </div>
                            </td>                                                                                                                                                                       
                        </tr>                       
                    </tbody>
                </table>
            </div>
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 lg:w-fit mx-auto my-2 w-full">  
                <h1 class="p-2 dark:text-surface-200 font-bold text-xl" >Data Destination</h1>              
                <table class="table-widget dark:text-surface-200 dark:bg-surface-800 w-full">                    
                    <tbody>                        
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Name:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataDestination.name.$error" class="text-red-600 text-sm">Please enter a Name for data destination.</div>                                  
                                    <InputText type="text" v-model="entity.dataDestination.name" class="w-full"
                                    :invalid="v$.entity.dataDestination.name.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataDestination.name.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataDestination.name.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>
                            <!-- status input -->
                            <td class="p-2"><span class="inline-block lg:w-32 w-full font-bold">Type:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataDestination.type.$error" class="text-red-600 text-sm">Please enter a type for data destination.</div>                                  
                                    <InputText type="text" v-model="entity.dataDestination.type" class="w-full"
                                    :invalid="v$.entity.dataDestination.type.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataDestination.type.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataDestination.type.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>                                                                                                                  
                        </tr> 
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Port:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataDestination.port.$error" class="text-red-600 text-sm">Please enter a port for data destination.</div>                                  
                                    <InputText type="text" v-model="entity.dataDestination.port" class="w-full"
                                    :invalid="v$.entity.dataDestination.port.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataDestination.port.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataDestination.port.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>
                            <!-- status input -->
                            <td class="p-2"><span class="inline-block lg:w-32 w-full font-bold">Host:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataDestination.host.$error" class="text-red-600 text-sm">Please enter a host for data destination.</div>                                  
                                    <InputText type="text" v-model="entity.dataDestination.host" class="w-full"
                                    :invalid="v$.entity.dataDestination.host.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataDestination.host.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataDestination.host.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>                                                                                                                  
                        </tr> 
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">User:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">                                    
                                    <InputText type="text" v-model="entity.dataDestination.user" class="w-full"/>                                    
                                </div>
                            </td>
                            <!-- status input -->
                            <td class="p-2"><span class="inline-block lg:w-32 w-full font-bold">Password:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">                                    
                                    <InputText type="text" v-model="entity.dataDestination.password" class="w-full"/>                                   
                                </div>
                            </td>                                                                                                                  
                        </tr> 
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Database:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataDestination.database.$error" class="text-red-600 text-sm">Please enter database for data destination.</div>                                  
                                    <InputText type="text" v-model="entity.dataDestination.database" class="w-full"
                                    :invalid="v$.entity.dataDestination.database.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataDestination.database.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataDestination.database.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>
                            <!-- status input -->
                            <td class="p-2"><span class="inline-block lg:w-32 w-full font-bold">Table:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">  
                                    <div v-if="v$.entity.dataDestination.table.$error" class="text-red-600 text-sm">Please enter a table for data destination.</div>                                  
                                    <InputText type="text" v-model="entity.dataDestination.table" class="w-full"
                                    :invalid="v$.entity.dataDestination.table.$error"
                                    />
                                    <div :class="{ 'hidden': v$.entity.dataDestination.table.$error }">
                                        <div class="text-red-600" v-for="error of v$.entity.dataDestination.table.$errors" :key="error.$uid">
                                            <div>{{ error.$message }}</div>
                                        </div>
                                    </div>                                    
                                </div>
                            </td>                                                                                                                  
                        </tr>  
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Ingestion Name input -->
                            <td class="p-2">
                                <span class="inline-block lg:w-32 w-full font-bold">Time Column:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">                                    
                                    <InputText type="text" v-model="entity.dataDestination.timeColumn" class="w-full"/>                                    
                                </div>
                            </td>                                                                                                                                                                       
                        </tr>                       
                    </tbody>
                </table>
            </div> 
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 w-full lg:w-[55.5rem] mx-auto my-2 ">               
                <DataTable :value="entity.fieldsMapping" size="small" >
                          
                    
                    <template #header>
                        <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                            <span class="text-xl text-900 font-bold">Field Mapping</span>
                            <Button icon="pi pi-plus" title="Add a new condition" class="ml-auto"
                                    @click="fnAddCondition" rounded size="small" />
                        </div>
                    </template>
                    <template #empty>
                        No mapping found.
                    </template>
                    
                    <Column field="source" header="Data Source Fields" class="w-60">
                        <template #body="{ data}">
                            <InputText type="text" v-model="data.source" placeholder="Source" class="w-full"/>                                                      
                        </template>                                                              
                    </Column> 
                    <Column field="dest" header="Data Destination Fields" class="w-60">
                        <template #body="{ data}">
                            <InputText type="text" v-model="data.dest" placeholder="Source" class="w-full"/>                                                      
                        </template>                                                              
                    </Column>                     
                    <Column :exportable="false" class="w-16">
                        <template #body="{ index }">
                            <div class="w-12">
                                <Button  icon="pi pi-trash" outlined rounded severity="danger" size="small" title="Delete"
                                        class="dark:text-red-300 dark:border-red-300"
                                        @click="fnDeleteCondition($event, index)" />
                            </div>
                        </template>
                    </Column>                                   
                </DataTable>
            </div>            
            <div class="pt-2 px-2 lg:w-[56rem] mx-auto mt-2 flex flex-wrap">
                <div class="ml-auto">
                    <Button label="Back" title="Go to Alerts page" severity="secondary" class="mr-2"
                            @click="fnCancel" icon="pi pi-arrow-circle-left" iconPos="left"></Button>
                    <Button v-if="isEditing" label="Delete" title="Delete Alert." severity="danger" class="mr-2" 
                            @click="fnOpenConfirmBox" icon="pi pi-times-circle" iconPos="right" />
                    <Button v-if="!isEditing" label="Add" title="Add a new Alert."
                            @click="fnSave" icon="pi pi-plus-circle" iconPos="right" />
                    <Button v-if="isEditing" label="Save" title="Save changes."
                            @click="fnSave" icon="pi pi-check-circle" iconPos="right" />
                </div>
            </div>        
        </div>
    </form>
    <Toast></Toast>
    <confirmation-component :msg="confirmObj.message"
                            :isConfirmationOpen="confirmObj.isOpen"
                            @close="fnCloseConfirmBox"
                            @ok="fnDelete"></confirmation-component>
</template>

<script>
    import LoaderMiniComponent from "@/components/LoaderMiniComponent.vue";
    import ConfirmationComponent from "@/components/ConfirmationComponent.vue";

    //import RadioButton from 'primevue/radiobutton';
    //import Checkbox from 'primevue/checkbox';
    import Button from 'primevue/button';
    import Textarea from 'primevue/textarea';
    import InputText from 'primevue/inputtext';
    //import InputNumber from 'primevue/inputnumber';
    import Dropdown from 'primevue/dropdown';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    import Toast from "primevue/toast";
    import { ToastSeverity } from 'primevue/api';
    import { useConfirm } from "primevue/useconfirm";

    import { useVuelidate } from '@vuelidate/core'
    //import { helpers, decimal } from '@vuelidate/validators'
    import { required, } from '@vuelidate/validators'
    
    import {ingestService} from "../services/IngestService.js"
    //import { catalogService } from "../services/CatalogService.js";

    //import Constants from "../utils/Constants.js";
    import { capitalizeFirstLetter } from "../utils/utils.js";
    import Calendar from "primevue/calendar";

    export default {
        name: "AlertComponent",
        emits: ["onError", "onCancel", "onSave", "onAdd", "onDelete"],
        setup() {
            return { v$: useVuelidate() }
        },
        components: {
            LoaderMiniComponent,
            ConfirmationComponent,
            //RadioButton, Checkbox,
            Textarea, 
            InputText, //InputNumber,
            Dropdown,
            Button,
            DataTable,Column,
            Calendar, Toast
        },
        props: {
            ingestId: String,
            isOpen: Boolean,
            presetAlert: Object
        },
        data() {
            return {
                isLoading: false,
                isEditing: false,
                calendarVisible:false,
                status: [
                    { id: "Started", name: "started" },
                    { id: "Stopped", name: "stopped" },                    
                ],
                users: [],                              
                entity: {
                    id: undefined,                    
                    name: "",
                    description:"",
                    status:undefined,
                    startDate: new Date(),
                    dataSource: {
                        name: "",
                        type: "",
                        host: "",
                        port: 0,
                        user: "",
                        password: "",
                        database: "",
                        table: "",
                        timeColumn: ""
                        },
                    dataDestination: {
                        name: "",
                        type: "",
                        host: "",
                        port: 0,
                        user: "",
                        password: "",
                        database: "",
                        table: "",
                        timeColumn: ""
                        },
                    fieldsMapping: []  
                },
                confirmObj: {
                    message: "",
                    isOpen: false
                },                
            }
        },
        validations() {
            return {
                entity: {
                    name: { required },
                    description: { required },
                    status: { required },
                    startDate: { required },    
                    dataSource: {
                        name: { required },
                        type: { required },
                        host: { required },
                        port: { required },
                        user: "",
                        password: "",
                        database: { required },
                        table: { required },
                        timeColumn: ""
                        }, 
                        dataDestination: {
                        name:{ required },
                        type: { required },
                        host: { required },
                        port: { required },
                        user: "",
                        password: "",
                        database: { required },
                        table: { required },
                        timeColumn: ""
                        },                                  
                    /*conditions: {
                        $each: helpers.forEach({
                            field: { required },
                            condition: { required }
                            threshold: { decimal, requiredIf: requiredIf(function () { return this.entity.condition < 7; }) },
                            thresholdMin: { decimal, requiredIf: requiredIf(function () { return this.entity.condition > 6; }) },
                            thresholdMax: { decimal, requiredIf: requiredIf(function () { return this.entity.condition > 6; }) }
                        })
                    }*/
                }
            }
        },
        watch: {
            isOpen: function (val) {
                console.log("Is Open changed - " + this.isOpen.toString());
                this.isEditing = false;
                if (val == true) {
                    console.log("ingestionID");
                    
                    console.log(this.ingestId);
                    
                    if (this.ingestId != undefined && this.ingestId.length > 0) {
                        console.log("Loading alert...");
                        //console.log(this.alertId);
                        this.fnLoad();
                        this.isEditing = true;
                    }
                    else if (this.presetAlert != undefined) {
                        //console.log(this.presetAlert);
                        this.fnSetEntity(this.presetAlert);
                    }
                    else {
                        console.log("Opening and loading Catalogs...");
                        this.fnLoadCatalogs();
                    }

                }
            },             
        },
        created() {
            console.log("Alert Component: Created");
            this.confirm = useConfirm();
        },    
        computed: {
                calendarDate: {
                get() {
                    // Pass a Date object to the Calendar component
                    return new Date(this.entity.startDate);
                },
                set(newDate) {
                    // Directly update the entity.startDate with a Date object in the correct format
                    if (newDate) {
                    // Set the new date with the original timezone offset intact
                    this.entity.startDate = newDate;
                    }
                },
                },
            },    
        methods: {              
                    
            toggleCalendar() {
                this.calendarVisible = !this.calendarVisible
            },
            logField(field, index) {
            console.log(`Field at index ${index}:`, field);
                },            
            fnSave: function () {
                // Do some stuff
                this.v$.$validate()
                    .then(result => {
                                                
                        //TODO: Validate inner conditions.

                        if (result) {

                            this.isLoading = true;
                            //alert("fnSave - Adding an Alert...");

                            const newIngestion = this.fnGetEntity(); 
                            console.log("This is entity");
                                                                                   
                            console.log(newIngestion);
                            
                            ingestService.save(newIngestion).then((data) => {
                                //console.log(data);
                                if (!this.isEditing) {

                                    newIngestion.ingestId = data.upserted_id;
                                    this.$emit('onAdd', newIngestion);
                                }
                                else {
                                    this.$emit('onSave', newIngestion);
                                }

                                this.isEditing = true;
                                this.isLoading = false;
                                this.$emit('onClose');
                                return true;
                            })
                            .catch(error => {
                                console.log(error);
                                this.$emit('onError', 'Error', 'An error occuerd when trying to save the alert!', ToastSeverity.ERROR);

                                this.isLoading = false;
                            });
                        }

                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                return false;
            },
            
            fnLoad() {                                                
                this.entity.id = this.ingestId;
                this.isLoading = true;

                ingestService.get(this.ingestId)
                    .then((data) => {
                        console.log("Ingestion Data");                        
                        console.log(data);
                        //console.log(data);
                        if (data != undefined) {
                            this.fnSetEntity(data);
                            this.isEditing = true;
                        }
                        else {
                            this.$emit('onError', 'Error', 'Alert was NOT found', ToastSeverity.ERROR);
                        }
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$emit('onError', 'Error', 'Error when loading data...!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnDelete() {
                
                this.isLoading = true;

                ingestService.delete(this.entity.id)
                    .then(() => {
                        //.then((data) => {
                        //console.log(data);
                        this.confirmObj.message = "";
                        this.confirmObj.isOpen = false;

                        this.$emit('onDelete', this.entity.id, this.entity.name);

                        this.fnCancel();
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$emit('onError', 'Error', 'An error occurred when trying to delete the alert!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnCancel() {
                this.$emit('onCancel');
            },
            fnGetEntity() {
                let obj = {
                    ingestId: this.entity.id,
                    name: this.entity.name,
                    description: this.entity.description,                    
                    status: undefined,
                    startDate: this.entity.startDate,                    
                    fieldsMapping: [],
                    dataSource: this.entity.dataSource,
                    dataDestination: this.entity.dataDestination, 
                    projectId: this.$store.getters.project, 
                    
                };
                if(this.entity.status=="Stopped"){
                    obj.status = 0
                }
                else{
                    obj.status = 1
                }
                this.entity.fieldsMapping.forEach((item) => {                                                            
                    obj.fieldsMapping.push({
                        source: item.source,
                        dest: item.dest
                    })
                })               

                return obj;
            },
            fnSetEntity(data) {                                                           
                this.entity.name = data.name;
                this.entity.description = data.description;
                if(data.status == 0){
                    this.entity.status = "Stopped";
                }  
                else{
                    this.entity.status = "Started";
                }              
                this.entity.startDate = data.importingStartDate;
                this.entity.dataSource.name = data.dataSource.name
                this.entity.dataSource.type = data.dataSource.type
                this.entity.dataSource.host = data.dataSource.host
                this.entity.dataSource.port = data.dataSource.port
                this.entity.dataSource.user = data.dataSource.user
                this.entity.dataSource.password = data.dataSource.password
                this.entity.dataSource.database = data.dataSource.database
                this.entity.dataSource.table = data.dataSource.table
                this.entity.dataSource.timeColumn = data.dataSource.timeColumn

                this.entity.dataDestination.name = data.dataDestination.name
                this.entity.dataDestination.type = data.dataDestination.type
                this.entity.dataDestination.host = data.dataDestination.host
                this.entity.dataDestination.port = data.dataDestination.port
                this.entity.dataDestination.user = data.dataDestination.user
                this.entity.dataDestination.password = data.dataDestination.password
                this.entity.dataDestination.database = data.dataDestination.database
                this.entity.dataDestination.table = data.dataDestination.table
                this.entity.dataDestination.timeColumn = data.dataDestination.timeColumn

                this.entity.fieldsMapping = data.fieldsMapping                
                                                   
            },
            fnOpenConfirmBox: function () {
                this.confirmObj.message = "Do you really want to delete the alert: '" + this.entity.name + "'?";
                this.confirmObj.isOpen = true;
            },
            fnCloseConfirmBox: function () {
                this.confirmObj.message = "";
                this.confirmObj.isOpen = false;
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnAddCondition() {
                if (this.entity.fieldsMapping == undefined) {
                    this.entity.fieldsMapping = [];
                }
                this.entity.fieldsMapping.push(this.fnGetConditionUI());
            },
            fnGetConditionUI() {
                return {                                        
                };
            },
            fnDeleteCondition(event, index) {                                
                this.confirm.require({
                    target: event.currentTarget,
                    message: 'Do you want to delete this condition?',
                    icon: 'pi pi-info-circle',
                    rejectClass: 'p-1 text-sm text-white dark:text-surface-900 bg-surface-500 dark:bg-surface-400 border border-surface-500 dark:border-surface-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-surface-600 dark:hover:bg-surface-300 hover:border-surface-600 dark:hover:border-surface-300 focus:ring-surface-400/50 dark:focus:ring-surface-300/50',
                    acceptClass: 'p-1 text-sm text-white dark:text-surface-900 bg-red-500 dark:bg-red-400 border border-red-500 dark:border-red-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300 focus:ring-red-400/50 dark:focus:ring-red-300/50',
                    rejectLabel: 'Cancel',
                    acceptLabel: 'Delete',
                    position: "topright",
                    accept: () => {
                        console.log("Delete item: " + index.toString());
                        this.entity.fieldsMapping.splice(index, 1);
                    },
                    reject: () => {
                        console.log("Cancel... Do nothing.");
                    }
                });
            },            
            
        }
    };
</script>
<style scoped>
    table {
        text-align: left;
    }

    th {
        padding: 0.75rem 0.75rem 0.75rem 0.25rem;
    }

    td {
        padding: 0.25rem;
    }
</style>

