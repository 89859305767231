<template>
  <div>    
    <iframe
      :src="iframeSrc"
      :key="reloadKey"
      frameborder="0"
      style="width: 100%; height: 600px"
    ></iframe>
  </div>
</template>

<script>
import { eventBus } from "@/utils/eventBus";

export default {
  props: {
    chartObject: Object,    
  },
  data() {
    return {
      activeProject: "",
      url: "",
      reloadKey: 0,
      newEvent:eventBus,
    };
  },  
  computed: {
    iframeSrc() {                            
      return this.url +`?activeProject=${encodeURIComponent(this.$store.getters.project)}`
    }
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        console.log("--------- New Iframe Value");
        console.log(newValue);        
        let config = newValue.configuration;
        config = config.replace(/'/g, '"');
        this.url = JSON.parse(config)["url"];
      },
    },
    newEvent: {
            immediate: true,
            handler(response) {                                            
                if(response.robots.length >=1)                
                {
                if(response.robots[0].collection == "robots")
                {                
                  this.reloadKey += 1; 
                }                
                }              
            },
            deep: true 
        }
  },
};
</script>

<style scoped>
/* You can add some styles here if needed */
</style>
