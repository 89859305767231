<template>
  <div class="flex align-middle px-5">
    <h2 class="text-4xl leading-tight text-center m-auto text-bold">Robots Information</h2>
    <!--<Button type="button" label="ASSET" icon="pi pi-plus-circle" iconPos="right"
        class="ml-auto" title="Add a new Asset" @click="fnAddNew"></Button>-->
  </div>
  <div>
    <dynamic-widget-component tab="robots"></dynamic-widget-component>
  </div>
  <div class=" py-3 flex-auto ">
    <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
      <h3 class="text-indigo-600 text-center text-xl"
          style="text-transform: uppercase; font-weight: bold">
        ROBOTS
      </h3>
      <DataTable :value="tableData" stripedRows class="w-full" size="small"
                 paginator :rows="10" :rowsPerPageOptions="[10, 20, 30, 40, 50, 100]"
                 sortField="createdAt" :sortOrder="-1"
                 ref="dt"
                 v-model:filters="filters" filterDisplay="row" :loading="isLoading"
                 :globalFilterFields="filterFields"
                 exportFilename="ROBOTS"
                 >
          <template #header>
                    <div class="text-end">
                        <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                    </div>
                </template>
        <template #empty>
          No robots found.
        </template>
        <template #loading>
          Loading robots data. Please wait...
        </template>
        <Column :exportable="false" bodyClass="dark:text-surface-200" headerClass="text-lg">
          <template #body="slotProps">
            <Button icon="pi pi-chart-bar" outlined rounded size="small" title="Open Graph"
                    class="dark:text-primary-300 dark:border-primary-300"
                    @click="fnOpenGraph(slotProps.data)" />
          </template>
        </Column>
        <Column :exportable="false" bodyClass="dark:text-surface-200" headerClass="text-lg">
          <template #body="slotProps">
            <Button icon="pi pi-search" outlined rounded size="small" title="Details"
                    class="dark:text-red-300 dark:border-red-300"
                    @click="fnDetailsOpen(slotProps.data.assetId, slotProps.data.name)" />
          </template>
        </Column>
        <Column field="companyId" header="Id" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-32" placeholder="Search by Id" size="small" />
          </template>
        </Column>
        <Column field="name" header="Asset" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-[16rem]" placeholder="Search by name" size="small" />
          </template>
        </Column>
        <Column field="disposed" header="Active" sortable :showFilterMenu="false" bodyClass="dark:text-surface-200" headerClass="text-lg">
          <template #body="{ data }">
            <Tag :value="data.disposed ? 'Disposed' : 'Active'" :severity="data.disposed ? 'danger' : 'success'" />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown v-model="filterModel.value" @change="filterCallback" :options="statuses" optionLabel="text" optionValue="value"
                      placeholder="Select" :showClear="false" inputClass="p-2 w-[6rem]">
              <template #option="slotProps">
                <Tag :value="slotProps.option.text" :severity="slotProps.option.value == 'true' ? 'danger' : 'success'" />
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column field="lifetimeSpan" header="Maximum (hr)" dataType="numeric" sortable bodyClass="dark:text-surface-200">
          <template #body="{ data }">
            {{ fnFormatNumber(data.lifetimeSpan) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
          </template>
        </Column>
        <Column field="operationalTime" header="Operational (hr)" dataType="numeric" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
          <template #body="{ data }">
            {{ fnFormatNumber(data.operationalTime) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
          </template>
        </Column>
        <Column field="lifetimeLeft" header="Remaining (hr)" dataType="numeric" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
          <template #body="{ data }">
            {{ fnFormatNumber(data.lifetimeLeft) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
          </template>
        </Column>
        <Column field="lifetimeEstimated" header="Predicted (hr)" dataType="numeric" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
          <template #body="{ data }">
            {{ fnFormatNumber(data.lifetimeEstimated) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
          </template>
        </Column>
        <Column field="failureProbability" header="Failure (%)" dataType="numeric" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
          <template #body="{ data }">
            {{ fnFormatPercentage(data.failureProbability/100) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" :maxFractionDigits="2" suffix="%" />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
  <Toast></Toast>
  <PrimeDialog v-model:visible="showModel" modal
               v-model:header="modelHeader"
               :style="{ width: '50rem' }"
               :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <div>
      <span class="font-bold">Failure Probability: </span>
      <span class="font-medium">{{ fnFormatPercentage(failureProbability) }}</span>
    </div>
    <img width="600" :src="imageUrl" alt="Asset Type Forecast" />
  </PrimeDialog>
  <!--<robot-details-component :entityId="details.entityId" :entityName="details.entityName" :isOpen="details.isOpen" @onWorkAdded="fnWorkAdded"
                           @onError="fnShowToast" @onDispose="fnDispose" @onClose="fnDetailsClose">
  </robot-details-component>-->
</template>

<script>
  import DynamicWidgetComponent from "@/components/DynamicWidgetComponent";
  import LoaderComponent from "@/components/LoaderComponent.vue";
  //import RobotDetailsComponent from '@/components/Robots/RobotDetailsComponent'
    
  import Button from 'primevue/button';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import InputText from 'primevue/inputtext';
  import InputNumber from 'primevue/inputnumber';
  import PrimeDialog from "primevue/dialog";
  import Tag from "primevue/tag";
  import Dropdown from "primevue/dropdown";

  import { ToastSeverity } from 'primevue/api';
  import { FilterMatchMode } from 'primevue/api';

  import { assetService } from "@/services/AssetService.js";
  import { stellioService } from "@/services/StellioService.js";
  import { ref } from "vue";  
  
  import {
    capitalizeFirstLetter,
    formatNumber,
    formatPercentage,
    convertBase64ToImage,    
  } from "@/utils/utils.js";

  import Toast from "primevue/toast";
  import { useToast } from "primevue/usetoast";
  import { eventBus } from "@/utils/eventBus";

    export default {
        name: "RobotsPage",
        components: { 
            DynamicWidgetComponent, 
            DataTable, Column, Button,
            InputText, InputNumber,
            PrimeDialog,
            LoaderComponent,
            //RobotDetailsComponent,
            Tag, Dropdown,
            Toast
        },
        data() {
            return {
                dt: ref(),
                isLoading: false,
                toastMessage:useToast(),
                newEvent:eventBus,
                tableData: [],
                filterFields: ['companyId', 'robotType', 'name', 'disposed'],
                filters: {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    companyId: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    robotType: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    lifetimeSpan: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                    lifetimeLeft: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                    lifetimeEstimated: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                    operationalTime: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                    failureProbability: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                    disposed: { value: null, matchMode: FilterMatchMode.EQUAL_TO }
                },
                modelHeader: "",
                showModel: false,
                details: {
                    entityId: undefined,
                    entityName: undefined,
                    isOpen: false
                },
                statuses: [{ text: 'Disposed', value: "true" }, { text: 'Active', value: "false" }]
            }
        },
        watch:{
          newEvent: {
            immediate: true,
            handler(newCollection) {                
                if(newCollection.collection.length >=1)                
                {
                if(newCollection.collection[0].collection == "assets")
                {
                this.toastMessage.add({severity: 'success', detail: `New data has been added from ${newCollection.collection[0].collection} collection` })
                this.fnLoad();
                  }                
                }              
            },
            deep: true 
        }
        },
        created() {
            console.log("Robot Manager: Created");
          this.$store.dispatch("parseHash").
            then(() => {
              this.fnLoad();
            });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        methods: {
          exportCSV  () {
          this.$refs.dt.exportCSV()                            
        },                  
            fnLoad: function () {
                this.isLoading = true;
                this.tableData = [];                                               
                assetService.find(this.$store.getters.project, {"assetType": "robot"})                                
                    .then((data) => {                                              
                        data.forEach((item) => { item.failureProbability *= 100; }); 
                        
                        this.tableData = data;                        


                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.fnShowToast('Error', 'Error when loading robots...!!!', ToastSeverity.ERROR);
                    });
                return;
            },
          async fnOpenGraph(asset) {
            //console.log("Open chart for Robot Type: ", asset);
            const data = {
              simulator: "Survival_analysis",
              simulatorName: "Survival_analysis",
              inputs: {},
              params: {},
              schedule: {
                type: "click",
              },
              projectId: this.$store.getters.project
            };

            data["inputs"] = {
              id: asset.companyId,
              type: asset.type,
              operationalTime: asset.operationalTime,
              disposed: asset.disposed,
              assetType: asset.assetType
            };

            stellioService.simulate(data)
              .then((data) => {
                //console.log(data);
                if (data != undefined &&
                  data.failureProbability != undefined) {
                  this.modelHeader = this.fnCapText(asset.assetType) + " forecast.";
                  this.showModel = true;

                  this.failureProbability = data.failureProbability;
                  this.imageUrl = convertBase64ToImage(data.image);
                }
              })
              .catch((error) => {
                console.log(error);
                this.fnShowMessage('Error Loading Graph', `An error occurred when trying to load the Graph for ${asset.type}: '${asset.name}'!!!`, ToastSeverity.ERROR);
              });
          },
            fnDetailsOpen(assetId) {
              
                this.$router.push({
                    name: "asset-details",
                    params: { entityId: assetId }
                });
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnCapText(text){
                return capitalizeFirstLetter(text);
            },
            fnFormatNumber(number){
                return formatNumber(number);
            },
            fnFormatPercentage(number) {
                return formatPercentage(number);    
            }
        }
    }
</script>
