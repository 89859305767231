<template>
    <div role="status" v-if="isLoading">
        <loader-mini-component></loader-mini-component>
    </div>
    <form id="frmBatch" name="frm-batch" ref="frmBatch">
        <div class="px-5 py-2  text-left">
            <div class="border-2 shadow-md rounded-md border-indigo-400 p-2 lg:w-fit mx-auto my-2 w-full">
                <table class="table-widget dark:text-surface-200 dark:bg-surface-800 w-full">
                    <tbody>
                        <tr class="flex flex-col lg:flex-row">
                            <!-- Alert Name input -->
                            <td class="p-2 ">
                                <span class="mt-2 inline-block lg:w-32 w-full font-bold">Batch:</span>
                            </td>
                            <td class="p-2 ">
                                <div class="inline-block w-full lg:w-72">
                                    <div v-if="v$.entity.batch.$error" class="text-red-600 text-sm">Please enter a Name to identify the Batch.</div>
                                    <InputText type="text" v-model="entity.batch" class="w-full"
                                               :invalid="v$.entity.batch.$error" />
                                </div>
                            </td>
                            <td class="p-2">
                                <span class="mt-2 inline-block lg:w-40 w-full font-bold">Date and time:</span>
                            </td>
                            <td class="p-2">
                                <div class="inline-block lg:w-64 w-full">
                                    <div v-if="v$.entity.timestamp.$error" class="text-red-600 text-sm">Please enter the date and time for the Batch.</div>
                                    <Calendar id="calendar-24h" v-model="batchDateTime" showTime hourFormat="24" class="w-full" dateFormat="yy-mm-dd"
                                              :invalid="v$.entity.timestamp.$error " />
                                </div>
                            </td>
                        </tr>
                        <tr class="flex flex-col lg:flex-row">
                            <td class="p-2"><span class="mt-2 inline-block lg:w-32 w-full font-bold">Recipe:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-72 w-full">
                                    <span v-if="v$.entity.recipeId.$error" class="text-red-600 text-sm">Please select a Recipe.</span>
                                    <Dropdown v-model="entity.recipeId" :options="recipeList" optionLabel="name" optionValue="id"  placeholder="Please Select one"
                                              class="lg:w-5/6 w-full" :invalid="v$.entity.recipeId.$error" @change="fnRecipeChanged" /> 
                                    <span v-if="entity.recipeId!=undefined" @click="fnRecipeDetails(entity.recipeId)"
                                          class="text-xl pl-2 pi pi-list hover:cursor-pointer hover:underline" >
                                    </span>
                                </div>
                            </td>
                            <td class="p-2"><span class="mt-2 inline-block lg:w-40  w-full font-bold">Quantity Produced:</span></td>
                            <td class="p-2">
                                <div class="inline-block lg:w-64 w-full">
                                    <div v-if="v$.entity.quantity.produced.$error" class="text-red-600 text-sm">Please enter a numeric value greater than or equal to 1.</div>
                                    <span class="inline-block">
                                        <InputNumber v-model="quantityProduced" inputId="minmaxfraction" :maxFractionDigits="4" inputClass="lg:w-24 w-full" class="mr-2"
                                                     :invalid="v$.entity.quantity.produced.$error" />
                                        <!--<Dropdown v-model="entity.unit" :options="measureUnits" optionLabel="text" optionValue="value" />-->
                                        <span>{{ entity.unit }}</span>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
    <div class="w-[57.25rem] mb-2 mx-auto" v-if="isEditing">
        <div class="w-full border-2 p-2 shadow-md table-widget rounded-md border-indigo-400 my-2">
            <table class="table-auto w-full dark:text-surface-200 dark:bg-surface-800">
                <thead>
                    <tr>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100" colspan="4">
                            Quantities ({{entity.unit}})
                        </th>
                    </tr>
                    <tr>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Produced</th>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Used</th>
                        <th class="text-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">Remaining</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.quantity.produced) }}</td>
                        <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.quantity.used) }}</td>
                        <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.quantity.remaining) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="w-[57.25rem] mb-2 mx-auto" v-if="isEditing">
        <div class="w-full border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
            <div class="flex align-middle">
                <h3 class="text-indigo-600 text-center m-auto text-bold"
                    style="text-transform: uppercase;">
                    Tasks
                </h3>
                <Button v-if="entity.quantity.remaining > 0"
                        icon="pi pi-plus" title="Link to a task" class="ml-auto"
                        @click="fnTaskOpen" rounded size="small" />
            </div>
            <DataTable :value="entity.tasks" size="small" scrollHeight="18rem"
                       paginator :rows="4" :rowsPerPageOptions="[4, 8, 12, 25, 50]">
                <template #empty>
                    No tasks linked.
                </template>
                <Column field="timestamp" header="Date">
                    <template #body="{ data }">
                        {{ fnFormatTextDate(data.timestamp) }}
                    </template>
                </Column>
                <Column field="jobName" header="Job"></Column>
                <Column field="task" header="Task"></Column>
                <Column field="quantity" header="Quantity Used">
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.quantity) }} {{ data.unit }}
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <div class="w-[57.25rem] mb-2 mx-auto" v-if="isEditing">
        <div class="w-full border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
            <div class="flex align-middle">
                <h3 class="text-indigo-600 text-center m-auto text-bold"
                    style="text-transform: uppercase;">
                    Incidents
                </h3>
                <Button icon="pi pi-plus" title="Link to an incident" class="ml-auto"
                        @click="fnIncidentOpen" rounded size="small" />
            </div>
            <DataTable v-if="incidentList.length > 0" :loading="incidentLoading"
                       :value="incidentList"  size="small" scrollHeight="18rem"
                       paginator :rows="4" :rowsPerPageOptions="[4, 8, 12, 25, 50]">
                <template #empty>
                    No Incidents linked.
                </template>
                <Column field="issueNumber" header="Number"></Column>
                <Column field="title" header="Title"></Column>
                <Column field="category" header="Category"></Column>
                <Column field="priority" header="Priority"></Column>
                <Column field="raisedDate" header="Date">
                  <template #body="{ data }">
                    {{ fnFormatTextDate(data.raisedDate) }}
                  </template>
                </Column>
                <Column :exportable="false" class="w-16">
                    <template #body="{ index, data }">
                        <div class="w-12">
                            <Button icon="pi pi-trash" outlined rounded severity="danger" size="small" title="Delete"
                                    class="dark:text-red-300 dark:border-red-300"
                                    @click="fnDeleteCondition($event, index, data.incidentId)" />
                        </div>
                    </template>
                </Column>
            </DataTable>
            <div v-else>No Incidents linked.</div>
        </div>
    </div>
    <div class="pt-2 px-2 lg:w-[56rem] mx-auto mt-2 flex flex-wrap">
      <div class="ml-auto">
        <Button label="Back" title="Go to Alerts page" severity="secondary" class="mr-2"
                @click="fnCancel" icon="pi pi-arrow-circle-left" iconPos="left"></Button>
        <template v-if="isEditing">
          <Button label="Delete" title="Delete Alert." severity="danger" class="mr-2"
                  @click="fnOpenConfirmBox" icon="pi pi-times-circle" iconPos="right" />
          <Button v-if="entity.tasks == undefined || entity.tasks.length < 1" 
                  label="Save" title="Save changes."
                  @click="fnSave" icon="pi pi-check-circle" iconPos="right" />
          <Button v-else label="Save" title="Save changes."
                  @click="fnUpdate" icon="pi pi-check-circle" iconPos="right" />
        </template>
        <Button v-else label="Add" title="Add a new Alert."
                @click="fnSave" icon="pi pi-plus-circle" iconPos="right" />
</div>
    </div>
    <assign-task-component :batchEntity="entity" :isOpen="openTaskWindow"
                           @onError="fnShowToast" @onAssignTask="fnAssignedTask" @onClose="fnTaskClose" />
    <assign-incident-component :batchEntity="entity" :isOpen="openIncidentWindow"
                               @onError="fnShowToast" @onAssign="fnIncidentAssigned" @onClose="fnIncidentClose" />
    <details-table-component :showModel="showRecipeDetails" :selectedRow="selectedRow"
                             @onClose="fnDetailsClose"></details-table-component>
</template>
<script>
    import Button from 'primevue/button';
    import Dropdown from 'primevue/dropdown';
    import InputText from 'primevue/inputtext';
    import InputNumber from 'primevue/inputnumber';
    import Calendar from 'primevue/calendar';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    import { ToastSeverity } from 'primevue/api';
    import { useConfirm } from "primevue/useconfirm";

    import { useVuelidate } from '@vuelidate/core';
    import { required, decimal, minValue } from '@vuelidate/validators';

    import { formatNumber, formatDate, dateTimeToString } from "@/utils/utils.js";
    import { stringHasValue, capitalizeFirstLetter, arrayHasValue } from "@/utils/utils.js";
    import Constants from "@/utils/Constants.js";
    
    import LoaderMiniComponent from '@/components/LoaderMiniComponent'
    import AssignTaskComponent from "@/components/Concrete/AssignTaskComponent.vue";
    import AssignIncidentComponent from "@/components/Concrete/AssignIncidentComponent.vue";
    import DetailsTableComponent from "@/components/Tables/DetailsTableComponent.vue";

    import { concreteBatchService } from "@/services/ConcreteBatchService.js";
    import { incidentService } from "@/services/IncidentService.js";
    import { stellioService } from "@/services/StellioService.js";

    export default {
        name: "ConcreteBatchDetails",
        emits: ["onError", "onCancel", "onSave", "onAdd", "onDelete"],
        setup() {
            return { v$: useVuelidate({ $scope: "details" }) }
        },
        components: {
            Button, Calendar, 
            InputText, InputNumber, Dropdown,
            LoaderMiniComponent,
            DataTable, Column,
            AssignTaskComponent,
            AssignIncidentComponent,
            DetailsTableComponent
        },
        props: {
            entityId: String,
            isOpen: Boolean
        },
        data() {
            return {
              isLoading: false,
              visible: false,
              isEditing: false,
              quantityProduced: undefined,
              batchDateTime: new Date(),
              entity: {
                  batchId: undefined,
                  projectId: undefined,
                  batch: undefined,
                  recipeName: "",
                  recipeId: undefined,
                  timestamp: undefined,
                  quantity: {
                      produced: undefined,
                      used: 0,
                      remaining: 0
                  },
                  unit: "kg",
                  updatedBy: undefined,
                  tasks: [],
                  incidents: []
              },
              incidentList: [],
              measureUnits: [
                  { text: "kg", value: "kg" },
                  { text: "ton", value: "ton" },
                  { text: "lt", value: "lt" },
                { text: "m�", value: "m3" }
              ],
              recipeList: [ ],
              openTaskWindow: false,
              openIncidentWindow: false,
              incidentLoading: false,
              confirm: undefined,
              showRecipeDetails: false,
              selectedRow: {}
            }
        },
        validations() {
            return {
                entity: {
                    batch: { required },
                    recipeName: { required },
                    recipeId: { required },
                    timestamp: { required },
                    unit: { required },
                    quantity: {
                        produced: { required, decimal, minValue: minValue(1) }
                    },
                }
            }
        },
        watch: {
          isOpen: function (val) {
            if (val == true) {
              this.visible = true;
              this.isEditing = false;
              console.log("Concrete Batch Component Opened! - ", this.entityId);
              this.fnLoadRecipes();
              this.entity.batchId = this.entityId;
              //this.textHeader = "Dispose Asset: " + this.assetName;
              if (stringHasValue(this.entityId)) {
                this.isEditing = true;
                this.fnLoadEntity();
              }
              else {
                let today = new Date();
                this.entity.projectId = this.$store.getters.project;
                this.getBatchName(today);
                this.batchDateTime = today;
              }
            }
          },
          quantityProduced: function(val) {
            this.entity.quantity.produced = val;
            this.entity.quantity.remaining = val - this.entity.quantity.used;
          },
          batchDateTime: function (val) {
            this.entity.timestamp = dateTimeToString(val);
          },
        },
        created() {
            console.log("Alert Component: Created");
            this.confirm = useConfirm();
        },
        methods: {
            getBatchName(today) 
            {
              let yy = today.getFullYear();
              let mm = today.getMonth() + 1; // Months start at 0!
              let dd = today.getDate();
              if (dd < 10) dd = '0' + dd;
              if (mm < 10) mm = '0' + mm;

              let stringDate = `${yy}${mm}${dd}`;
              concreteBatchService.getBatch(stringDate)
                .then((data) => {
                  this.entity.batch = data;
                  this.isLoading = false;
                })
                .catch((error) => {
                  console.log(error);
                  //this.$emit('onError', 'Error loading.', 'An error occurred when trying to Load the Concrete batch: "' + this.entity.batch + '"!!!', ToastSeverity.ERROR);
                  
                  let randomNum = Math.random().toString();
                  
                  this.entity.batch = `${stringDate}-${randomNum.substr(randomNum.indexOf(".")+1, 4)}`;
                  this.isLoading = false;
                });
            },
            fnLoadEntity() {
                this.isLoading = true;
                concreteBatchService.get(this.entityId)
                    .then((data) => {
                      //console.log("data", data);
                      this.entity = data;
                      
                      this.quantityProduced = this.entity.quantity.produced;
                      this.batchDateTime = new Date(this.entity.timestamp);
                      if (arrayHasValue(data.incidents)){
                        this.fnLoadIncidents();
                      }
                      this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$emit('onError', 'Error loading.', 'An error occurred when trying to Load the Concrete batch: "' + this.entity.batch + '"!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnLoadIncidents() {
                this.incidentLoading = true;
                let ids = this.entity.incidents.map((x) => x.incidentId);
                console.log(ids);
                incidentService.list(ids)
                    .then((data) => {
                        //console.log(data);
                        this.incidentList = data;
                        this.incidentLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$emit('onError', 'Error loading.', 'An error occurred when trying to Load the Concrete Batch Incidents!!!', ToastSeverity.ERROR);
                        this.incidentLoading = false;
                    });
            },
            fnLoadRecipes() {
                stellioService.list(this.$store.getters.project, Constants.StellioEntities.RECIPES)
                    .then((data) => {
                        //console.log(JSON.stringify(data));
                        this.recipeList = data;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$emit('onError', 'Error Loading', 'An error occurred when trying to load the Jobs from Stellio!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnCancel() {
                this.fnCleanForm();
                this.visible = false;
                this.$emit('onCancel');
            },
            fnSave: function () {
                // Do some stuff
                this.v$.$validate()
                    .then((result) => {
                        //console.log("fnsave", result);
                        if (result) {
                            //console.log("fnsave", result);

                            this.isLoading = true;
                            this.entity.updatedBy = this.$store.getters.username;

                            concreteBatchService.save(this.entity).then((data) => {
                                console.log("concreteBatchService", data);
                                if (!this.isEditing) {

                                    this.entity.batchId = data.upserted_id;
                                    this.entity.quantity.remaining = this.entity.quantity.produced;
                                    this.entity.quantity.used = 0;
                                    this.entity.tasks = [];
                                    this.$emit('onAdd', this.entity);
                                }
                                else {
                                    this.$emit('onSave', this.entity);
                                }

                                this.isEditing = true;
                                this.isLoading = false;
                                //this.$emit('onClose');
                                return true;
                            })
                            .catch(error => {
                                console.log(error);
                                this.$emit('onError', 'Error saving.', 'An error occured when trying to save the Concrete Batch!', ToastSeverity.ERROR);
                                this.isLoading = false;
                            });
                        }

                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                return false;
          },
          fnUpdate() {
            this.v$.$validate()
              .then((result) => {
                //console.log("fnsave", result);
                if (result) {
                  //console.log("fnsave", result);

                  this.isLoading = true;
                  this.entity.updatedBy = this.$store.getters.username;
                  //this.entity.batchId = data.upserted_id;

                  concreteBatchService.update(this.entity).then((data) => {
                    //console.log("concreteBatchService.update", data);
                    this.$emit('onSave', this.entity);

                    this.entity.updatedAt = data.updatedAt;

                    this.isEditing = true;
                    this.isLoading = false;
                    //this.$emit('onClose');
                    return true;
                  })
                    .catch(error => {
                      console.log(error);
                      this.$emit('onError', 'Error saving.', 'An error occured when trying to save the Concrete Batch!', ToastSeverity.ERROR);
                      this.isLoading = false;
                    });
                }

                return false;
              })
              .catch(error => {
                console.log(error);
              });
            return false;
          },
            fnOpenConfirmBox: function () {
                //this.confirmObj.message = "Do you really want to delete the alert: '" + this.entity.name + "'?";
                //this.confirmObj.isOpen = true;
            },
            fnCloseConfirmBox: function () {
                //this.confirmObj.message = "";
                //this.confirmObj.isOpen = false;
            },
            fnTaskOpen() {
                this.openTaskWindow = true;
            },
            fnTaskClose() {
                this.openTaskWindow = false;
            },
            fnAssignedTask(assigned, batchName){
                //TODO: Add Task to Array.
                
                this.fnShowToast('Assign Success', `The Concrete batch "${batchName}" has been added to the task "${assigned.task}".`, ToastSeverity.SUCCESS);

                if (this.entity.tasks == undefined || !Array.isArray(this.entity.tasks))
                {    
                    this.entity.tasks = [];
                }
                this.entity.tasks.push(assigned);
                this.entity.quantity.used += assigned.quantity;
                this.entity.quantity.remaining -=  assigned.quantity;   
            },
            fnIncidentOpen() {
                this.openIncidentWindow = true;
            },
            fnIncidentClose() {
                this.openIncidentWindow = false;
            },
            fnIncidentAssigned(batchIncident, incident) {
                this.fnShowToast('Assign Success', `The Incident "${incident.title}" has been linked to this Concrete Batch.`, ToastSeverity.SUCCESS);

                if (this.entity.incidents == undefined || !Array.isArray(this.entity.incidents)) {
                    this.entity.incidents = [];
                }
                this.entity.incidents.push(batchIncident);
                this.incidentList.push(incident);
            },
            fnRecipeChanged(event){

                let obj = this.recipeList.find(o => o.id === event.value);

                this.entity.recipeName = obj.name;
                //console.log(JSON.stringify(obj));
            },
            fnCleanForm: function () {
                this.entity = {
                    batchId: undefined,
                    batch: undefined,
                    recipeName: "",
                    recipeId: undefined,
                    timestamp: undefined,
                    quantity: {
                        produced: undefined,
                        used: 0,
                        remaining: 0
                    },
                    unit: "kg",
                    updatedBy: undefined,
                    tasks: [],
                    incidents: []
                };
                this.incidentList = [];
                this.$refs.frmBatch.reset();
                this.v$.$reset();
            },
            fnDeleteCondition(event, index, incidentId) {
                this.confirm.require({
                    target: event.currentTarget,
                    message: 'Do you want to delete this incident?',
                    icon: 'pi pi-info-circle',
                    rejectClass: 'p-1 text-sm text-white dark:text-surface-900 bg-surface-500 dark:bg-surface-400 border border-surface-500 dark:border-surface-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-surface-600 dark:hover:bg-surface-300 hover:border-surface-600 dark:hover:border-surface-300 focus:ring-surface-400/50 dark:focus:ring-surface-300/50',
                    acceptClass: 'p-1 text-sm text-white dark:text-surface-900 bg-red-500 dark:bg-red-400 border border-red-500 dark:border-red-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300 focus:ring-red-400/50 dark:focus:ring-red-300/50',
                    rejectLabel: 'Cancel',
                    acceptLabel: 'Delete',
                    position: "topright",
                    accept: () => {
                        this.incidentLoading = true;
                        //console.log("Delete item: " + index.toString());
                        let removeIncident = {
                                linkedBy: this.$store.getters.username,
                                batchId: this.entity.batchId, 
                                incidentId: incidentId
                            };
                        console.log("Remove item: ", JSON.stringify(removeIncident));

                        incidentService.removeConcrete(removeIncident)
                            .then((data) => {
                                console.log(data);
                                this.incidentList.splice(index, 1);
                                this.incidentLoading = false;
                            })
                            .catch((error) => {
                                console.log(error);
                                this.$emit('onError', 'Error loading.', 'An error occurred when trying to Load the Concrete Batch Incidents!!!', ToastSeverity.ERROR);
                                this.incidentLoading = false;
                            });
                    },
                    reject: () => {
                        console.log("Cancel... Do nothing.");
                    }
                });
            },
            fnFormatNumber(number) {
                return formatNumber(number);
            },
            fnFormatTextDate(textValue) {
                return formatDate(new Date(textValue));
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnRecipeDetails(recipeId)
            {
                console.log("recipeId", recipeId);
                stellioService.get(recipeId)
                    .then((data) => {
                        //console.log(JSON.stringify(data));
                        console.log("----- ROW -----");
                        this.selectedRow[data["type"]] = data;
                        if (Object.keys(this.selectedRow).length > 0) {
                            this.showRecipeDetails = true;
                        }
                        else
                        {
                            this.fnShowToast('Error Loading', 'An error occurred when trying to load the Recipe details from Stellio!!!', ToastSeverity.WARN);
                        }
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.fnShowToast('Error Loading', 'An error occurred when trying to load the Recipe details from Stellio!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnDetailsClose(){
                this.showRecipeDetails = false;
            }
        }
    };
</script>
<style scoped>
</style>

