<template>
    <h2 class="text-4xl leading-tight text-center m-auto text-bold">Concrete Batches</h2>
    <div class="flex align-middle px-5">    
        <Button type="button" label="BATCH" icon="pi pi-plus-circle" iconPos="right"
                class="ml-auto" title="Concrete Batch" @click="fnAddNew"></Button>
    </div>
    <div class="px-5 py-3 flex-auto">
        <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
            <h3 class="text-indigo-600 text-center text-xl"
                style="text-transform: uppercase; font-weight: bold">
                Concrete Batches
            </h3>
            <DataTable :value="tableData" stripedRows class="w-full" size="small"
                       paginator :rows="10" :rowsPerPageOptions="[10, 20, 30, 40, 50, 100]"
                        sortField="timestamp" :sortOrder="-1"
                        ref="dt"                        
                       v-model:filters="filters" filterDisplay="row" :loading="isLoading"
                       :globalFilterFields="filterFields"
                       exportFilename = "Concrete Batches"
                       >
                <template #header>
                    <div class="text-end">
                        <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                    </div>
                </template>
                <template #empty>
                    No Concrete batches found.
                </template>
                <template #loading>
                    Loading Concrete Batches data. Please wait...
                </template>
                <Column :exportable="false" bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" outlined rounded size="small" title="Edit"
                                class="dark:text-primary-300 dark:border-primary-300"
                                @click="fnEdit(slotProps.data.batchId)" />
                    </template>
                </Column>
                <Column :exportable="false" bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" outlined rounded severity="danger" size="small" title="Delete"
                                class="dark:text-red-300 dark:border-red-300"
                                @click="fnOpenConfBox(slotProps.data.batchId, slotProps.data.batch)" />
                    </template>
                </Column>
                <Column field="batch" header="Batch" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="{ data }">
                        {{ data.batch }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-[10rem]" placeholder="Search by batch" size="small" />
                    </template>
                </Column>
                <Column field="recipeName" header="Recipe" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="{ data }">
                        <span class="hover:cursor-pointer hover:underline" @click="fnRecipeDetails(data.recipeId)">
                            {{ data.recipeName }}
                        </span>
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-[12rem]" placeholder="Search by recipe" size="small" />
                    </template>
                </Column>
                <Column field="timestamp" header="Created Date" sortable dataType="date" bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #body="{ data }">
                        {{ fnFormatDate(data.timestamp) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">                        
                        <Calendar v-model="filterModel.value" dateFormat="dd M yy" placeholder="dd mmm yyyy" mask="99 aaa 9999"  @date-select="filterCallback" inputClass="w-[8rem] p-2" />
                    </template>
                </Column>
                <Column field="produced" dataType="numeric" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #header>
                        Produced (kg)
                    </template>
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.produced) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value"  @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
                    </template>
                </Column>
                <Column field="used" dataType="numeric" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #header>
                        Used (kg)
                    </template>
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.used) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
                    </template>
                </Column>
                <Column field="remaining" dataType="numeric" sortable bodyClass="dark:text-surface-200" headerClass="text-lg">
                    <template #header>
                        Remaining (kg)
                    </template>
                    <template #body="{ data }">
                        {{ fnFormatNumber(data.remaining) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputNumber v-model="filterModel.value" @input="filterCallback" inputClass="w-[6rem] p-2" size="small" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <loader-component :isLoading="isLoading" type="primary"></loader-component>
    <Toast></Toast>
    <confirmation-component :msg="confirmDelete.message"
                            :isConfirmationOpen="confirmDelete.isOpen"
                            @close="fnCloseConfBox"
                            @ok="fnDelete"></confirmation-component>
    <details-table-component :showModel="showRecipeDetails" :selectedRow="selectedRow"
                             @onClose="fnDetailsClose"></details-table-component>
</template>
<script>

    import LoaderComponent from "@/components/LoaderComponent.vue";
    import ConfirmationComponent from "@/components/ConfirmationComponent.vue";
    import DetailsTableComponent from "@/components/Tables/DetailsTableComponent.vue";
    
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import Button from 'primevue/button';
    import InputText from 'primevue/inputtext';
    import Calendar from 'primevue/calendar';
    import InputNumber from 'primevue/inputnumber';

    import { ToastSeverity } from 'primevue/api';
    import { ref } from "vue";  
    import { FilterMatchMode } from 'primevue/api';

    import { stellioService } from "@/services/StellioService.js";
    import { concreteBatchService } from "@/services/ConcreteBatchService.js";
    import { capitalizeFirstLetter, formatNumber, formatDate
    } from "@/utils/utils.js";
    import { eventBus } from "@/utils/eventBus";
    import Toast from "primevue/toast";
    import { useToast } from "primevue/usetoast";

    export default {
        name: "BatchManagerPage",
        components: {
            ConfirmationComponent,
            LoaderComponent,
            DetailsTableComponent,
            DataTable, Column, 
            Button, InputText, 
            InputNumber, 
            Calendar,
            Toast
        },
        data() {
            return {
                dt: ref(),
                isLoading: false,                
                tableData: [],
                newEvent:eventBus,
                toastMessage:useToast(),
                confirmDelete: {
                    message: "",
                    isOpen: false,
                    batchId: "",
                    alertName: ""
                },
                addSave: {
                    batchId: undefined,
                    isOpen: false
                },
                connections: [],
              filterFields: ['batch', 'recipeName', 'produced', 'used', 'remaining', 'unit', 'timestamp'],
                filters: {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    batch: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    recipeName: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    produced: { value: null, matchMode: FilterMatchMode.EQUALS },
                    used: { value: null, matchMode: FilterMatchMode.EQUALS },
                    remaining: { value: null, matchMode: FilterMatchMode.EQUALS },
                    unit: { value: null, matchMode: FilterMatchMode.EQUALS },
                    timestamp: { value: null, matchMode: FilterMatchMode.DATE_IS }
                },
                showRecipeDetails: false,
                selectedRow: {}
            };
        },
        watch:{
            newEvent: {
            immediate: true,
            handler(newCollection) {                                                
                if(newCollection.collection.length >=1)                
                {
                if(newCollection.collection[0].collection == "concrete-batches")
                {
                this.toastMessage.add({severity: 'success', detail: `New data has been added from ${newCollection.collection[0].collection} collection` })
                this.fnLoad();
                }
                }
            },
            deep: true 
        }
        },
        created() {
            console.log("Concrete Batch Manager: Created");
            this.$store.dispatch("parseHash").
                then(() => {
                    this.fnLoad();
                });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        unmounted() {
            console.log("Unmounting Concrete Batch Manager Page...");

        },
        methods: {           
            exportCSV  () {                                                              
            console.log(this.$refs.dt.first);
            
            //this.$refs.dt.exportCSV()               
        },                   
            fnLoad: function () {
                this.isLoading = true;
                this.tableData = [];
                //TODO: Call
                concreteBatchService.find(this.$store.getters.project)
                    .then((data) => {                        
                        if (data != undefined && Array.isArray(data)) {

                            data.forEach((currentValue) => {                                
                                if (currentValue["timestamp"] !== undefined && currentValue.timestamp.toString().length > 9) {
                                  currentValue["timestamp"] = new Date(currentValue["timestamp"].substring(0, 10));
                                }
                                currentValue.produced = currentValue.quantity.produced;
                                currentValue.used = currentValue.quantity.used;
                                currentValue.remaining = currentValue.quantity.remaining;
                            });
                            this.tableData = data;
                        }                        
                        this.tableLoader = false;
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.fnShowToast('Error', 'Error when loading Concrete Batches...!!!', ToastSeverity.ERROR);
                    });
                return;
            },
            fnOnAdd(entityAdded) {
                this.fnShowToast('Save Success', 'The Concrete Batch "' + entityAdded.name + '" was inserted!', ToastSeverity.SUCCESS);
                //alert("Update Alert: " + batchId);
                this.tableData.push(entityAdded);
            },
            fnOnSave(entityNew) {
                this.fnShowToast('Save Success', 'The Concrete Batch "' + entityNew.name + '" was updated!', ToastSeverity.SUCCESS);
                //alert("Update Alert: " + batchId);
                const currentIndex = this.tableData.findIndex(p => p.batchId === entityNew.batchId);

                this.tableData.splice(currentIndex, 1, entityNew);
            },
            fnOnDelete(batchId, entityName) {

                this.fnShowToast('Delete Success', 'The Concrete Batch "' + entityName + '" was deleted!', ToastSeverity.WARN);
                //console.log('warning - The alert "' + entityName + '" was deleted!');

                const currentIndex = this.tableData.findIndex(p => p.batchId === batchId);
                this.tableData.splice(currentIndex, 1);
            },
            fnDelete() {
                this.loading = true;

                concreteBatchService.delete(this.confirmDelete.batchId)
                    .then(() => {                        
                        this.fnOnDelete(this.confirmDelete.batchId, this.confirmDelete.alertName);

                        this.confirmDelete.message = "";
                        this.confirmDelete.isOpen = false;
                        this.confirmDelete.batchId = "";
                        this.confirmDelete.alertName = "";

                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.fnShowToast('Error', 'An error occurred when trying to delete the alert "' + this.confirmDelete.alertName + '"!!!', ToastSeverity.ERROR);
                        this.loading = false;
                    });
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnOpenConfBox: function (entityId, entityName) {
                this.confirmDelete.batchId = entityId;
                this.confirmDelete.alertName = entityName;
                this.confirmDelete.message = "Do you really want to delete the Concrete Batch: '" + entityName + "'?";
                this.confirmDelete.isOpen = true;
            },
            fnCloseConfBox() {
                this.confirmDelete.batchId = "";
                this.confirmDelete.alertName = "";
                this.confirmDelete.message = "";
                this.confirmDelete.isOpen = false;
            },
            fnAddNew() {
                this.$router.push({ name: "batch-details" });
                //this.alerComp.batchId = "";
                //this.alerComp.isOpen = true;
            },
            fnEdit: function (entityId) {
                //this.alerComp.batchId = batchId;
                //this.alerComp.isOpen = true;
                this.$router.push({
                    name: "batch-details",
                    params: { entityId: entityId }
                });
            },
            fnCloseAlertComp() {
                this.alerComp.isOpen = false;
                this.alerComp.batchId = "";
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnFormatNumber(number) {
                return formatNumber(number);
            },
            fnFormatDate(dateValue) {
                return formatDate(dateValue);
            },
            fnRecipeDetails(recipeId){                
                stellioService.get(recipeId)
                    .then((data) => {
                        //console.log(JSON.stringify(data));
                        console.log("----- ROW -----");
                        this.selectedRow[data["type"]] = data;
                        if (Object.keys(this.selectedRow).length > 0) {
                            this.showRecipeDetails = true;
                        }
                        else
                        {
                            this.fnShowToast('Error Loading', 'An error occurred when trying to load the Recipe details from Stellio!!!', ToastSeverity.WARN);
                        }
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.fnShowToast('Error Loading', 'An error occurred when trying to load the Recipe details from Stellio!!!', ToastSeverity.ERROR);
                        this.isLoading = false;
                    });
            },
            fnDetailsClose(){
                this.showRecipeDetails = false;
            }
        }
    };

</script>
