<template>
    <PrimeDialog v-model:visible="openModel" maximizable modal
                 header="View"
                 :style="{ width: '50rem' }"
                 :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
                 @hide="fnCloseModel">
        <div v-if="!showTable">
        <div v-if="tableSource=='SensorBox'" class="flex justify-end items-center space-x-4 p-4">                        
            <Button @click="getData(temporalUrl)">Show historic data</Button>            
        </div>
        <div v-if="!editDoc">
            <div v-if="openImageModel">
                <img width="600" :src="imageUrl" alt="Picture.." />
            </div>            
            <div v-for="(value, key) in selectedRow" :key="key" class="m-5 p-5 row">
                <div class="font-bold key mb-4">
                    <u>{{ fnCapText(key) }}</u>                    
                </div>
                <table>
                    <tbody>
                        <tr v-for="(itemValue, itemKey) in value"
                            :key="itemKey"
                            class="m-5 p-5 row">
                            <template v-if="itemKey != 'e_pos_x' && itemKey != 'e_pos_y'">
                                <td class="font-bold key w-48">{{ fnCapText(itemKey) }}</td>
                                <td>:</td>
                                <td class="m-2 p-3 block bg-violet-100 dark:text-slate-600">
                                    <div v-html="fnParseValue(itemValue)" />
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <table v-else>
            <tbody>
                <tr v-for="item in updateCols"
                    :key="item.column"
                    class="m-5 p-5 row">
                    <td class="font-bold key">{{ fnCapText(item.column) }}</td>
                    <td>:</td>
                    <td class="px-2 value w-full">
                        <InputText v-if="item.formInput == 'text'"
                                   v-model="editedRow[item.column]"
                                   class="bg-indigo-100 w-full edit-input dark:text-slate-600" />

                        <Dropdown v-if="item.formInput == 'dropdown'"
                                  v-model="editedRow[item.column]" :options="item.valueList" optionLabel="text" optionValue="value"
                                  placeholder="Please Select one" class="w-full" inputClass="edit-input bg-indigo-100 dark:text-slate-600" />

                        <InputNumber v-if="item.formInput == 'number'" :maxFractionDigits="6"
                                     v-model="editedRow[item.column]" inputClass="w-full edit-input bg-indigo-100 dark:text-slate-600" />
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="flex justify-end mt-4">
            <div v-if="enableEdit && !editDoc" @click="fnEditDoc" class="cursor-pointer">
                <span>
                    <i class="pi pi-file-edit" style="color: slateblue"></i>
                </span>
            </div>
            <div>
                <PrimeButton v-if="editDoc"
                             @click="fnUpdateCancel"
                             label="Cancel"
                             severity="secondary"
                             icon="pi pi-arrow-circle-left" iconPos="left"
                             class="mr-2" />
                <PrimeButton v-if="editDoc"
                             @click="fnUpdateDoc"
                             label="Update" title="Save changes."
                             icon="pi pi-check-circle" iconPos="right" />

            </div>
        </div>
        </div>                 
        <div v-else>
            <div v-if="loading" class="card flex justify-center">
                <ProgressSpinner />
            </div>
            <div class="card flex justify-start m-5">
                <Button @click="fnOpenModel()">Back</Button>            
            </div>           
            <table>
                    <tbody>
                        <tr 
                            class="m-5 p-5 row">
                            <template v-if="!loading">                                
                                <td class="m-2 p-3 block bg-violet-100 dark:text-slate-600">
                                    <div v-html="fnParseValue(tableData)" />
                                </td>
                            </template>                           
                        </tr>
                    </tbody>
                </table>
        </div>
    </PrimeDialog>
    
</template>
<script>
    import InputText from "primevue/inputtext";
    import InputNumber from "primevue/inputnumber";
    import Dropdown from "primevue/dropdown";
    import PrimeDialog from "primevue/dialog";
    import PrimeButton from "primevue/button";
    import axios from "axios";    
    //import DataTable from "primevue/datatable";
    //import Column from "primevue/column";    
    import Button from "primevue/button";
    import ProgressSpinner from 'primevue/progressspinner';
    

    import { parseJsonValue } from "@/utils/ParseJson.js";
    import {
        valueIsTrue, 
        tryParseFloat,
        arrayHasValue, 
        capitalizeFirstLetter
    } from "@/utils/utils.js";

    export default {
        name: "DetailsTableComponent",
        emits: ["onClose", "updateRow"],
        components: {
            PrimeDialog, PrimeButton,
            InputText, Dropdown, InputNumber,
            //DataTable,Column,
            Button,
            ProgressSpinner
        },
        props: {
            selectedRow: Object,
            showModel: Boolean,
            imageUrl: String,
            showImageModel: Boolean,
            updateCols: Object,
            enableEdit: Boolean,
            rowSchema: Object,
            tableSource: String,
            temporalUrl: String
        },        
        data() {
            return {
                openModel: false,
                openImageModel: false,
                editedRow: {},
                editDoc: false,
                tableData: [],
                showTable:false,
                loading: false,
                //selectedDays:null,
                //days: [{ text: '3 Days', value: "3" }, { text: '5 Days', value: "5" }],
                //updatedTemporalUrl:""
            };
        },
        watch: {            
            showModel: function (val) {                                
                this.openModel = val;
                this.fnLog();
            },
            showImageModel: function (val) {
                this.openImageModel = val;
            },
        //     selectedDays: function() {
        //     console.log("This are selected date: ", this.selectedDays.value,"Type: ",typeof this.selectedDays.value);
        //     this.updatedTemporalUrl = this.temporalUrl+"/"+this.selectedDays.value                                            
        //     this.getData(this.updatedTemporalUrl)
            
            
        // }
        },
        methods: {
            getData(url){
                this.showTable = true
                this.loading = true                                               
                axios
                    .get(url)
                    .then((response) => {                                                
                        let rawData = response.data.data;                                                
                        
                        let maxIndex = Math.max(
                            ...Object.values(rawData).map((v) => (Array.isArray(v) ? v.length : 1))
                        );

                        let formatted = [];                        
                        for (let i = 0; i < maxIndex; i++) {
                            let entity = {}
                            Object.keys(rawData).forEach((key) => {
                            let value = Array.isArray(rawData[key]) ? rawData[key][i].value || rawData[key][0].value : rawData[key];                                                                                                                                             
                            if(key!='e_pos_x' && key!='e_pos_y')
                            {
                            entity[key] = value                                                                                                                                          
                            }
                            });                      
                            formatted.push(entity)        
                        }                                                                                                                                             
                        
                        this.tableData = formatted                                                
                        this.loading = false                                                                                                                                
                    })
                    .catch((error) => {
                        console.error("Error loading data:", error);
                    });                
                
            },

            fnParseValue(text) {                                              
                return parseJsonValue(text);
            },
            fnCapText(text) {                
                return capitalizeFirstLetter(text);
            },
            fnLog() {
                console.log("selectedRow: ", this.selectedRow);
            },
            fnCloseModel() {
                this.editDoc = false;
                this.showTable = false
                this.editedRow = {};
                this.tableData = []            
                this.$emit("onClose")
            },

            fnOpenModel(){                
                this.showTable = false
            },

            fnEditDoc() {
                this.editDoc = true;
                this.editedRow = {};
                
                if (arrayHasValue(this.updateCols)) {

                    let fullRow = this.selectedRow[this.tableSource];
                    this.updateCols.forEach((col) => {

                        if (["number", "float", "int"].includes(this.rowSchema[col.column])) {
                            this.editedRow[col.column] = tryParseFloat(fullRow[col.column]);
                        }
                        else if (this.rowSchema[col.column] === "bool") {
                            this.editedRow[col.column] = valueIsTrue(fullRow[col.column]);
                        }
                        else if (this.rowSchema[col.column] === 'datetime') {
                            this.editedRow[col.column] = new Date(fullRow[col.column]);
                        }
                        else if (this.rowSchema[col.column] === "str") {
                            this.editedRow[col.column] = fullRow[col.column].trim();
                        }
                        else {
                            this.editedRow[col.column] = fullRow[col.column];
                        }
                    });
                }
            },
            fnUpdateCancel() {
                this.editDoc = false;
                this.editedRow = {};
            },
            fnUpdateDoc() {
                this.$emit("updateRow", this.editedRow);
                this.fnClose();
            }
        }
    };
</script>
