import App from "./App.vue";
import { createApp } from "vue";
import * as VueRouter from "vue-router";
import mitt from "mitt";
import PrimeVue from "primevue/config";
import i18n from "./plugins/i18n";

import "primeicons/primeicons.css";
import "./index.css";
import "gantt-schedule-timeline-calendar/dist/style.css";
import "primeicons/primeicons.css";

import Lara from "@/presets/lara"; //import preset
//import Wind from "@/presets/wind"; //import preset

import store from "./security/store.js";
import { auth } from "./security/Auth.js";

import { axiosService } from "./services/AxiosService.js";
import { logService } from "@/services/LogService.js";

import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";

import ErrorPage from "./pages/ErrorPage.vue";
import DefaultPage from "./pages/DefaultPage.vue";
import RedirectPage from "./pages/RedirectPage.vue";
import ForbiddenPage from "./pages/ForbiddenPage.vue";

import AlertManagerPage from "./pages/alert/AlertManagerPage.vue";
import AlertDetailsPage from "./pages/alert/AlertDetailsPage.vue";
import PresetAlertPage from "./pages/alert/PresetAlertPage.vue";
import PresetDetailsPage from "./pages/alert/PresetDetailsPage.vue";
import IngestionManagerPage from "./pages/IngestionManagerPage.vue";
import IngestionDetailPage from "./pages/IngestionDetailPage.vue";
import SelectProjectPage from "./pages/SelectProjectPage.vue";
import CreateProjectPage from "./pages/CreateProjectPage.vue";
import ProjectAlerts from "./pages/ProjectAlertsPage.vue";
import ProjectAlertsOnly from "./pages/ProjectAlertsOnly.vue";
import AssetManagementPage from "./pages/assets/AssetManagementPage.vue";
import AssetDetailsPage from "./pages/assets/AssetDetailsPage.vue";
import MaterialsPage from "./pages/materials/MaterialsPage.vue";
import RobotsPage from "./pages/robots/RobotsPage.vue";
import StructuralIntegrityPage from "./pages/structuralIntegrity/StructuralIntegrityPage.vue";
import BatchManagerPage from "./pages/concrete/BatchManagerPage.vue";
import BatchDetailsPage from "./pages/concrete/BatchDetailsPage.vue";

import IncidentManagerPage from "./pages/incident/IncidentManagerPage.vue";
import IncidentDetailsPage from "./pages/incident/IncidentDetailsPage.vue";

import ProjectConfiguratorDataModelPage from "./pages/projectConfigurator/ProjectConfiguratorDataModelPage.vue";
import ProjectGeneralInfo from "./pages/projectGenericInfo/ProjectGenericInfo.vue";
import AnomalyDetection from "./pages/anomalyDetection/AnomalyDetection.vue";

import VueGoogleMaps from "@fawmi/vue-google-maps";

import RobotInformationReportPage from "./pages/reports/RobotInformationReportPage.vue";
import IncidentReportPage from "./pages/reports/IncidentReportPage.vue";
import ConcreteReportPage from "./pages/reports/ConcreteReportPage.vue";

const routes = [
  {
    path: "/",
    name: "root",
    redirect: { name: "select-project" },
  },
  {
    path: "/dss",
    name: "dss",
    // component: DefaultPage,
    redirect: { name: "select-project" },
    children: [
      {
        path: "",
        name: "page-root",
        redirect: { name: "select-project" },
      },
      {
        path: "page",
        component: DefaultPage,
        // beforeEnter: ifAuthenticated,
        meta: { requiresAuth: true },
        children: [
          {
            path: "alert-manager",
            name: "alert-manager",
            component: AlertManagerPage,
          },
          {
            path: "alert-details/:alertId?",
            name: "alert-details",
            component: AlertDetailsPage,
          },
          {
            path: "ingestion-details/:ingestId?",
            name: "ingestion-details",
            component: IngestionDetailPage,
          },
          {
            path: "incident-management",
            name: "incident-management",
            component: IncidentManagerPage,
          },
          {
            path: "incident-report",
            name: "incident-report",
            component: IncidentReportPage,
          },
          {
            path: "robot-info-report",
            name: "robot-info-report",
            component: RobotInformationReportPage,
          },
          {
            path: "concrete-report",
            name: "concrete-report",
            component: ConcreteReportPage,
          },
          {
            path: "incident-details/:entityId?",
            name: "incident-details",
            component: IncidentDetailsPage,
          },
          {
            path: "preset-alert",
            name: "preset-alert",
            component: PresetAlertPage,
          },
          {
            path: "preset-details/:presetId",
            name: "preset-details",
            component: PresetDetailsPage,
          },
          {
            path: "ingestion-manager",
            name: "ingestion-manager",
            component: IngestionManagerPage,
            // beforeEnter: ifHasAdminPermission,
            //meta: { requiredRoles: ["admin"] },
          },
          {
            path: "select-project",
            name: "select-project",
            component: SelectProjectPage,
          },
          {
            path: "create-project",
            name: "create-project",
            component: CreateProjectPage,
            //meta: { requiredRoles: ["admin"] },
          },
          {
            path: "conf/:id",
            name: "project-configurator",
            component: ProjectConfiguratorDataModelPage,
            //meta: { requiredRoles: ["admin"] },
          },
          {
            path: "project-alerts",
            name: "project-alerts",
            component: ProjectAlerts,
          },
          {
            path: "alerts-only/:projectId?",
            name: "alerts-only",
            component: ProjectAlertsOnly,
          },
          {
            path: "asset-management",
            name: "asset-management",
            component: AssetManagementPage,
          },
          {
            path: "asset-details/:entityId?",
            name: "asset-details",
            component: AssetDetailsPage,
          },
          {
            path: "project-info",
            name: "project-info",
            component: ProjectGeneralInfo,
          },
          {
            path: "anomaly-detection",
            name: "anomaly-detection",
            component: AnomalyDetection,
          },
          {
            path: "materials",
            name: "materials",
            component: MaterialsPage,
          },
          {
            path: "robots",
            name: "robots",
            component: RobotsPage,
          },
          {
            path: "structural-integrity",
            name: "structural-integrity",
            component: StructuralIntegrityPage,
          },
          {
            path: "batch-management",
            name: "batch-management",
            component: BatchManagerPage,
          },
          {
            path: "batch-details/:entityId?",
            name: "batch-details",
            component: BatchDetailsPage,
          },
        ],
      },
      {
        path: "redirect",
        name: "redirect",
        component: RedirectPage,
      },
      {
        path: "error",
        name: "error",
        component: ErrorPage,
      },
      {
        path: "forbidden",
        name: "forbidden",
        component: ForbiddenPage,
      },
      {
        path: ":pathMatch(.*)*",
        component: ErrorPage,
      },
    ],
  },
];

let host = window.location.host;
let protocol = window.location.protocol;
let hostname = window.location.hostname;

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.getters.isAuthenticated) {
      checkPermission(to, next);
    } else {
      /*try {
        await store.dispatch("login");
        console.log("store.dispatch('login') called");
        checkPermission(to, next);
      } catch (error) {
        console.log(error);*/
      window.location.reload();
      //}
    }
  } else {
    next();
  }
});

function checkPermission(to, next) {
  let requiredRoles = to.meta.requiredRoles;

  if (!requiredRoles || requiredRoles.length === 0) {
    next();
    return;
  }

  let userRoles = store.getters.roles;
  if (
    Array.isArray(requiredRoles) &&
    requiredRoles.some((role) => userRoles.includes(role))
  ) {
    next();
  } else {
    next({ name: "forbidden" });
  }
}

const emitter = mitt();

console.log("Calling store.dispatch('login')");
await store.dispatch("login");
console.log("Finishing store.dispatch('login')");

const app = createApp(App);

let wsHost = host;
let wsPort = "ws:";
let drawflow = host;

if (protocol == "https:") {
  wsPort = "wss:";
}

//hostname = "192.168.50.229";

if (hostname === "localhost" || host.includes("192.168.")) {
  host = hostname + ":7575";
  wsHost = hostname + ":8000";
  drawflow = hostname + ":3000";
}

//host = hostname + ":7575";

console.log("Host: - " + host);
console.log("Hostname: - " + hostname);
console.log("Protocol: - " + protocol);

app.config.globalProperties.$apiBase = `${protocol}//${host}/dss-api`;
app.config.globalProperties.$drawflowBase = `${protocol}//${drawflow}/dme`;
// Create web socket variables.
app.config.globalProperties.$alertWS = `${wsPort}//${wsHost}/socket/alerts`;
app.config.globalProperties.$manWS = `${wsPort}//${wsHost}/socket/socket-man`;
app.config.globalProperties.$ingestWS = `${wsPort}//${wsHost}/socket/ingest-man`;
app.config.globalProperties.$tablesWS = `${wsPort}//${wsHost}/socket/alert-table`;

app.config.globalProperties.$dssVersion = "1.1.3";

console.log(app.config.globalProperties.$apiBase);

auth.setBaseApi(app.config.globalProperties.$apiBase);

axiosService.setBaseApi(app.config.globalProperties.$apiBase);

app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$logService = logService;

app.use(router);
app.use(store);
app.use(ToastService);
app.use(ConfirmationService);

app.use(PrimeVue, {
  unstyled: true,
  pt: Lara, //apply preset
});
app.use(i18n);

app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDXXXUVaUmu4uC8fe5xqRQSBTqygE856DE",
    libraries: "places",
    v: "weekly",
    // language: 'de',
  },
});

app.config.errorHandler = (err, instance, info) => {
  // handle error, e.g. report to a service
  console.log(err);
  console.log(instance);
  console.log(info);
};

app.mount("#app");
