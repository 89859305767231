import { reactive } from "vue";

export const eventBus = reactive({
  collection: [],
  robots: [],

  // Method to set a new alert
  setCollection(newCollection) {
    this.collection = [newCollection]; // Overwrite previous alert with the new one
  },
  setRobot(newRobot) {
    this.robots = [newRobot];
  },
});
